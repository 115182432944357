import dayjs from "dayjs";
export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString("en-AU", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const formatTime = (timeString) => {
  const date = new Date(`1970-01-01T${timeString}Z`);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const formatUTCTimeToLocalTime = (timeString) => {
  const date = new Date(`1970-01-01T${timeString}Z`);
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`
};

export const formatTimestampToMonthYear = (timestamp) => {
  const date = new Date(timestamp);
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-indexed
  const year = date.getFullYear();
  return `${month}/${year}`;
};

export const formatTimestampToddmmyyyy = (timestamp) => {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const compareTimePickerValues = (time1, time2) => {
  /// time1 and time2 are strings in the format "HH:MM:SS"
  const time1Array = time1.split(":").map(t => parseInt(t, 10));
  const time2Array = time2.split(":").map(t => parseInt(t, 10));
  if (time1Array[0] < time2Array[0]) {
    return -1; // time1 is earlier
  } else if (time1Array[0] > time2Array[0]) {
    return 1; // time1 is later
  } else {
    if (time1Array[1] < time2Array[1]) {
      return -1;
    } else if (time1Array[1] > time2Array[1]) {
      return 1;
    } else {
      return 0;
    }
  }
};
export const isEndDateIsTwoMonthsLaterAfterStartDate = (startDate, endDate) => {
  const startDateDaysjs = dayjs(startDate);
  const endDateDaysjs = dayjs(endDate);
  const twoMonthsLater = startDateDaysjs.add(2, "month");
  return endDateDaysjs.isAfter(twoMonthsLater);
}
export const get2MonthsLaterDate = (date) => {
  return dayjs(date).add(2, "month").format("YYYY-MM-DD");
}
export const isDateBeforeCurrentDate = (date) => {
  const yesterday = dayjs().subtract(1, "day");
  return dayjs(date).isBefore(yesterday);
}
export const compareTwoDates = (date1, date2) => {
  const date1Daysjs = dayjs(date1);
  const date2Daysjs = dayjs(date2);
  if (date1Daysjs.isBefore(date2Daysjs)) {
    return -1;
  } else if (date1Daysjs.isAfter(date2Daysjs)) {
    return 1;
  } else {
    return 0;
  }
}
export const isValidDate = (date) => {
  if (date === "") {
    return false;
  }
  const dateArray = date.split("-");
  if (dateArray.length !== 3) {
    return false;
  }
  const [year, month, day] = dateArray.map((d) => parseInt(d, 10));
  
  if (month < 1 || month > 12) {
    return false
  }
  if (day < 1 || day > 31) {
    return false
  }
  if (year < 1900 || year > 2100) {
    return false;
  }
  return true;
}

export function convertDateTimeToServerFormat(time) {
  return time.$H + ":" + time.$m + ":" + time.$s;
}

export function convertDateTimeToUTCFormat(time, shiftDate) {
  const date = new Date(shiftDate + " " + time).toISOString();
  return date;
}

export function convertDateTimeToStringFormat(time, shiftDate) {
  return new Date(shiftDate + " " + time).toLocaleString('en-US', {
    hour12: false
  })
}
export function calculateHourBetween2Times(date1, date2) {
  const timeDifference = date2 - date1
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60))
  return daysDifference
}
