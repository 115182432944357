import { assignmentGroupType, qualificationLevelId } from "./constants";
import theme from "./theme";

export function debounce(func, delay) {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }


export  function stringAvatar(name) {
    if(!name) return {'children': ''}
    
    if(name.split(' ').length === 1) return {
      children: `${name.split(' ')[0][0]}`,
    }
    try {
      return {
        sx: {
            bgcolor: theme.palette.primary.main,
          },
      children: `${name.split(' ')[0][0] || ''}${name.split(' ')[1][0] || ''}`,
    };
    } catch (error) {
      return {'children': ''}
    }
    
  }
  export function convertToVerbalDate(dateString) {
    if (!dateString) return ''
    const date =  new Date(dateString)
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`
}
export function convertTimeWithoutSeconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(':');
  const date = new Date()
  date.setHours(hours, minutes, seconds)
  return date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})
}

export function checkIfShiftIsOlderThanToday(shiftDate) {
    const today = getTodayDateWithoutTime()
    const shiftDateObj = new Date(shiftDate)
    const result = shiftDateObj.getTime() < today.getTime()
    return result
}
export function upperCaseFirstLetter(string) {
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function checkIfShiftIsOlderTwoDaysThanToday(shiftDate) {
  const today = getTodayDateWithoutTime()
  const shiftDateObj = new Date(shiftDate)
  const difference = Math.abs(today - shiftDateObj);
  const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));
  return daysDifference <= 2
}

function getTodayDateWithoutTime() {
    const today = new Date()
    return new Date(today.getFullYear(), today.getMonth(), today.getDate())
} 
export function checkIfDateIn7Day(currentDate) {
    if (!currentDate) return false
    const today = new Date()
    const sevenDaysBefore = new Date(today)
    sevenDaysBefore.setDate(today.getDate() - 7)
    const currentDateObj = new Date(currentDate)
    const result = currentDateObj.getTime() > sevenDaysBefore.getTime()
    return result
}
export function checkIfDateIn5Day(currentDate) {
    if(!currentDate) return false
    const today = new Date()
    const fiveDaysBefore = new Date(today)
    fiveDaysBefore.setDate(today.getDate() - 5)
    const currentDateObj = new Date(currentDate)
    const result = currentDateObj.getTime() > fiveDaysBefore.getTime()
    return result
}

function getDaySuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

/// exp: 31st January
export function convertDateWithDaySuffix(dateString) {
  const date = new Date(dateString)
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  return `${day}${getDaySuffix(day)} ${month}`
}
export function convertDateWithDayAndYear(dateString) {
  const date = new Date(dateString)
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  const day = date.getDate();
  return `${day}${getDaySuffix(day)} ${month} ${year}`
}

export function convertDateWithDayAndYearWithTime(dateString) {
  const date = new Date(dateString)
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  const day = date.getDate();
  const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
  return ` ${month} ${day}${getDaySuffix(day)}, ${year} ${time}`
}

/// exp: 15 Mon
export function convertDateWithDayOfWeek(dateString) {
  const date = new Date(dateString)
  const day = date.getDate();
  const dayOfWeek = date.toLocaleString('default', {weekday: 'short'})
  return `${day} ${dayOfWeek}`
}

/// exp: 2023-11-16
export function convertYearMonthDay(dateString) {
  const date = new Date(dateString)
  const day = date.getDate();
  const month = date.toLocaleString('default', {month: "numeric"});
  const year = date.toLocaleString('default', {year: "numeric"});
  return `${year}-${month}-${day}`
}

/// Convert this format: 2021-11-16T00:00:00.000Z to time only 00:00 with AM/PM
export function convertTimeOnly(timeString) {
  const date = new Date(timeString)
  return date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})
}

/// exp: 15
export function getDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}`;
}

/// exp: Nov
export function getMonth(dateString) {
  const date = new Date(dateString);
  return `${date.toLocaleString("default", { month: "short" })}`;
}

/// exp: 10:45:00
export function convertDateToTime(dateString) {
  const time = new Date(dateString).toLocaleTimeString("en-US", { hour12: false });
  return time.slice(0, -3)
}


// ex:from "2023-12-23" -> "23/12/2023"
export function convertDayMonthYear(dateString) {
  const parts = dateString.split("-");
  const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
  return formattedDate;
}
const compareDates = (date1, date2) => {
  if (date1 > date2) {
    return 1
  }
  if (date1 < date2) {
    return -1
  }
  return 0
}
export function isCurrentTimePassedShiftTime(currentTime, shiftDate, shiftStartTime) {
  const shiftDateObj = new Date(shiftDate)
  const hours = shiftStartTime.split(':')[0]
  const minutes = shiftStartTime.split(':')[1]
  shiftDateObj.setHours(hours)
  shiftDateObj.setMinutes(minutes)
  return compareDates(currentTime, shiftDateObj) === 1
}

export function cleanInputForDB(input) {
  if (!input) return ''
  return input.trim()
}

export function hasNoZeroOrSpaces(string) {
  return !string?.includes(' ') && !string?.includes('0')
}

export function hasNoSpaces(string) {
  return !string?.includes(' ')
}

export function hasNoSpacesAndIsNumber(string) {
  return !string?.includes(' ') && !isNaN(string)
}

export function validUrl(url) {
  if (!url) return false
  return url.includes('https://')
}

export function isIndividualAssigned(assigment_types) {
  return assigment_types?.startsWith('individual')
}

export function isFavoriteAssigned(assigment_types) {
  return assigment_types === assignmentGroupType.favorite
}

export const getQualificationDateLabel = (qualificationLevel) => {
  if (qualificationLevel === qualificationLevelId.DIPLOMA_TOWARD
      || qualificationLevel === qualificationLevelId.ECT_TOWARRD
      || qualificationLevel === qualificationLevelId.CERT_TOWARD) {
    return 'Enrolment date';
  }
  return 'Qualification date';
};

export const isValidPhoneNumber = (phoneNumber) => {
  return /^(04|05)\d{8}$/.test(phoneNumber) || /^\+61(4|5)\d{8}$/.test(phoneNumber);
}

export const formatToLocalPhoneNumber = (phoneNumber) => {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  if (digitsOnly.startsWith('61')) {
    return '0' + digitsOnly.slice(2);
  }
  return phoneNumber
};

export const extractState = (address) => {
  if(!address) return '';
  const statePattern = /\b(?:NSW|VIC|QLD|SA|WA|TAS|NT|ACT)\b/;
  const match = address.match(statePattern);
  return match ? match[0] : '';
}

export const extractSuburb = (address) => {
  if(!address) return '';
  const state = extractState(address)
  let fullAddress = address;
  if(state) {
    fullAddress = address.split(' ' + extractState(address))[0];
  }
  if(fullAddress?.includes(',')) {
    return fullAddress.split(',')[1];
  }
  return fullAddress;
}
