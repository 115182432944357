import React, { useCallback, useContext, useEffect, useState } from 'react'
import { OrgContext } from '../../../utils/context'
import OrgEducatorInviteForm from '../../forms/OrgEducatorInviteForm'
import OrgEducatorEditForm from '../../forms/OrgEducatorEditForm'
import { debounce } from 'lodash'
import {
  CustomTabs,
  CustomTab,
  Row,
  EducatorRow,
  PendingEducatorRow,
  BlacklistedRow
} from './components/styles'
import { Box, CircularProgress, Divider, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  AddButton,
  FilterButton,
  FilterButtonMultiple
} from '../../reusable/Buttons'
import { get, getLocalStorageEducatorTab, post, removeLocalStorageEducatorTab, setLocalStorageEducatorTab } from '../../../utils/api'
import { isOwner } from '../../../utils/auth'
import { SearchBox } from '../../reusable/TextField'
import { CancelInvitationModal, SuccessDialogModel } from '../../reusable/Modal'
import { PaddingBox, SpacedRow } from '../../reusable/Scaffolds'
import {
  ACCEPTED_TAB,
  BLACKLISTED_TAB,
  EDUCATOR_STATUSES,
  PENDING_TAB,
  availableFilter,
  educatorStatusFilter
} from '../../../utils/constants'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useNavigate } from 'react-router-dom'
import RemoveBlacklistForm from '../../forms/RemoveBlacklistForm'
import { M3BodyMedium, M3TitleLarge } from '../../reusable/TextStyles'
import CustomBadge from '../../reusable/BadgeStyles'


const Educators = ({ setAlert, currentUser }) => {
  const [currentTab, setCurrentTab] = useState(ACCEPTED_TAB)
  const [orgUsers, setOrgUsers] = useState(null)
  const [pendingOrgUsers, setPendingOrgUsers] = useState(null)
  const [blacklistedUsers, setBlacklistedUsers] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [privateEducatorsList, setPrivateEducatorsList] = useState([])
  // Invite user modal
  const [openAddUserModal, setOpenAddUserModal] = useState(false)
  const [openCancelInvitationModal, setOpenCancelInvitationModal] =
    useState(false)
  const [userError, setUserError] = useState(null)
  // Edit user modal
  const [userToEdit, setUserToEdit] = useState({})
  const [userToCancel, setUserToCancel] = useState({})
  const [openEditUserModal, setOpenEditUserModal] = useState(false)
  // Remove blacklist modal
  const [openRemoveBlacklistModal, setOpenRemoveBlacklistModal] =
    useState(false)

  const [valueSearch, setValueSearch] = useState('')
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [blacklistedCount, setBlacklistedCount] = useState(0);
  const [privateEducatorsCount, setPrivateEducatorsCount] = useState(0);

  const [qualificationList, setqualificationList] = useState([])
  const [ectLevelList, setEctLevelList] = useState([])
  const [selectedEducatorStatus, setSelectedEducatorStatus] = useState(0)
  const [percentCompletes, setPercentCompletes] = useState([])
  const [selectedQualifications, setSelectedQualifications] = useState([])
  const [searchString, setSearchString] = useState('')
  const [availableStatus, setAvailableStatus] = useState(1)
  const [successTitle, setSuccessTitle] = useState(null)
  const [content, setContent] = useState('')
  const [isFechingList, setIsFechingList] = useState(false)
  const [isAddingUser, setIsAddingUser] = useState(false)
  const theme = useTheme()

  const org = useContext(OrgContext)
  const org_id = org ? org.organisation_id : null

  const baseUrl = `/manage/organisations/${org_id ?? 0}/educators`
  const baseUrlBlacklisted = `/manage/organisations/${
    org_id ?? 0
  }/educators/black_listed`
  const basePrivateUrl = `/admin/educators/private`
  const fetchQualifications = async () => {
    try {
      const orgData = await get('/manage/qualifications', null, {
        is_profile: true
      })
      const qualificationMapped = orgData.qualifications.filter(qualification => qualification.id >= 0).map(
        (qualification) => {
          qualification.value = qualification.id
          qualification.name = qualification.qualification
          return qualification
        }
      )
      setqualificationList(qualificationMapped)
    } catch (error) {
      console.error(error)
    }
  }
  const fetchEctLevels = async () => {
    try {
      const ectLevels = await get('/manage/ect-levels', null)
      setEctLevelList(ectLevels.ectLevels)
    } catch (error) {
      console.error(error)
    }
  }

  
  const fetchPercentCompletes = async () => {
    try {
      const data = await get('/manage/percent-completes', null)
      setPercentCompletes(data.percentCompletes)
    } catch (error) {
      console.error(error)
    }
  }

  const setInitialTab = () => {
    const storedTab = getLocalStorageEducatorTab();
    if (storedTab) {
      setCurrentTab(parseInt(storedTab))
      removeLocalStorageEducatorTab();
    }
  }

  const fetchData = React.useCallback(
    async (textQuery, currentTab, selectedQualifications, availableStatus, selectedEducatorStatus) => {
      const params = {
        text_query: textQuery ?? '',
        qualification_list: selectedQualifications ?? [],
        available: availableStatus ?? 1,
        pending: currentTab === PENDING_TAB,
        status: EDUCATOR_STATUSES[selectedEducatorStatus] ?? 1
      }
      const educators = await get(baseUrl, null, params)
      if(currentTab === PENDING_TAB) {
        setPendingCount(educators.length)
        setPendingOrgUsers(educators)
      } else {
        setAcceptedCount(educators.length)
        setOrgUsers(educators)
      }
    },
    [baseUrl]
  )
  const cancelInvitation = (user_id) => {
    post(
      `/manage/organisations/${
        org_id ?? 0
      }/educators/${user_id}/cancel_invitation`
    )
      .then(() => {
        setOpenCancelInvitationModal(false)
        fetchData(
          searchString,
          currentTab,
          selectedQualifications,
          availableStatus
        )
      })
      .catch((error) => {
        setUserError(error.message)
      })
  }

  const fetchPrivateEducators = React.useCallback(
    async (searchString, selectedQualifications, availableStatus, selectedEducatorStatus) => {
      const privateEducators = await get(basePrivateUrl, null, {
        qualification_list: selectedQualifications ?? [],
        is_available: availableStatus,
        query: searchString,
        status: EDUCATOR_STATUSES[selectedEducatorStatus] ?? 1
      })
      setPrivateEducatorsList(privateEducators)
      setPrivateEducatorsCount(privateEducators.length)
    },
    [basePrivateUrl]
  )

  const fetchDataBlacklisted = React.useCallback(
    async (searchString, selectedQualifications) => {
      const params = {}
      if (searchString) {
        params.text_query = searchString
      }
      if (selectedQualifications.length > 0) {
        params.qualification_list = selectedQualifications
      }
      const userData = await get(baseUrlBlacklisted, null, params)
      setBlacklistedUsers(userData)
      setBlacklistedCount(userData.length)
    },
    [baseUrlBlacklisted]
  )

  const fetchAllDataDebounce = useCallback(() => {
    const fetchAllData = async (
      searchString,
      currentTab,
      fetchData,
      fetchPrivateEducators,
      fetchDataBlacklisted,
      selectedQualifications,
      availableStatus,
      isQuickcareAdmin
    ) => {
      setIsFechingList(true)
       // Fetch all on initial load
      if (!searchString && selectedQualifications.length === 0 && availableStatus === 1) {
        await fetchData(
          searchString,
          ACCEPTED_TAB,
          selectedQualifications,
          availableStatus,
          selectedEducatorStatus
        )
        await fetchData(
          searchString,
          PENDING_TAB,
          selectedQualifications,
          availableStatus,
          selectedEducatorStatus
        )
        if (isQuickcareAdmin) {
          await fetchPrivateEducators(
            searchString,
            selectedQualifications,
            availableStatus,
            selectedEducatorStatus
          )
        } else {
          await fetchDataBlacklisted(searchString, selectedQualifications)
        }
      }
      else if (currentTab === ACCEPTED_TAB || currentTab === PENDING_TAB) {
        await fetchData(
          searchString,
          currentTab,
          selectedQualifications,
          availableStatus,
          selectedEducatorStatus
        )
      } else if (currentTab === BLACKLISTED_TAB) {
        if (isQuickcareAdmin) {
          await fetchPrivateEducators(
            searchString,
            selectedQualifications,
            availableStatus,
            selectedEducatorStatus
          )
        } else {
          await fetchDataBlacklisted(searchString, selectedQualifications)
        }
      }
      setIsFechingList(false)
    }

    const debouncedFunction = debounce(
      () =>
        fetchAllData(
          searchString,
          currentTab,
          fetchData,
          fetchPrivateEducators,
          fetchDataBlacklisted,
          selectedQualifications,
          availableStatus,
          currentUser?.is_quickcare_admin
        ),
      0
    )
    return debouncedFunction()
  }, [
    searchString,
    currentTab,
    fetchData,
    fetchPrivateEducators,
    fetchDataBlacklisted,
    currentUser?.is_quickcare_admin,
    selectedQualifications,
    availableStatus,
    selectedEducatorStatus
  ])

  useEffect(() => {
    fetchAllDataDebounce()
  }, [fetchAllDataDebounce])

  useEffect(() => {
    fetchQualifications()
    fetchEctLevels()
    fetchPercentCompletes()
    setInitialTab()
  }, [])

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue)
    setSelectedQualifications([])
    setSearchString('')
    setValueSearch('')
  }

  const handleAddUserModal = () => {
    setOpenAddUserModal(true)
  }

  const handleRemoveBlacklistModal = (user) => {
    setUserToEdit(user)
    setOpenRemoveBlacklistModal(true)
  }

  const handleQualificationChange = (event) => {
    setSelectedQualifications(event.target.value)
  }
  // Same function is called to close add and edit modals
  const handleCloseUserModal = () => {
    setOpenAddUserModal(false)
    setOpenEditUserModal(false)
    setOpenCancelInvitationModal(false)
    setUserToEdit({})
    setUserError(null)
  }
  const handleCloseCancelModal = () => {
    setOpenCancelInvitationModal(false)
    setUserToCancel({})
  }
  let typingTimer
  let doneTypingInterval = 1000

  const onSearchTextChange = (e) => {
    setValueSearch(e.target.value)
    clearTimeout(typingTimer)
    typingTimer = setTimeout(
      () => setSearchString(e.target.value),
      doneTypingInterval
    )
  }

  
  const handleAddUser = ({
    first_name,
    last_name,
    qualification,
    email,
    phone_number,
    payroll_id,
    address,
    lat,
    lng,
    latest_qualification_date,
    ect_level,
    milestone_passed,
    previous_qualification
  }) => {
    setIsAddingUser(true)
    post(`/manage/organisations/${org_id ?? 0}/people`, {
      first_name,
      last_name,
      qualification,
      phone_number,
      email,
      payroll_id,
      address,
      lat,
      lng,
      access: 'educator',
      latest_qualification_date,
      ect_level,
      milestone_passed: milestone_passed === ''? null: milestone_passed,
      previous_qualification: (previous_qualification < 0 || previous_qualification === '') ? null: previous_qualification
    })
      .then((res) => {
        handleCloseUserModal()
        setIsAddingUser(false)
        setCurrentTab(PENDING_TAB)
        fetchData(
          searchString,
          PENDING_TAB,
          selectedQualifications,
          availableStatus
        )
        if (res.message) {
          setSuccessTitle(res.message)
        }
        if(res.content) {
          setContent(res.content)
        }
        setShowSuccess(true)
      })
      .catch((error) => {
        setIsAddingUser(false)
        setUserError(error.message)
        setAlert(error.message, 'error')
      })
  }

  const handleEditUserModal = (user) => {
    setUserToEdit(user)
    setOpenEditUserModal(true)
  }

  const handleEditUser = (user_id, user) => {
    post(`/manage/organisations/${org_id ?? 0}/people/${user_id}`, user)
      .then(() => {
        handleCloseUserModal()
        setCurrentTab(ACCEPTED_TAB)
        fetchData(
          searchString,
          currentTab,
          selectedQualifications,
          availableStatus
        )
        setAlert('Educator updated successfully')
      })
      .catch((error) => {
        setUserError(error.message)
      })
  }

  const getListDataByTab = () => {
    if (currentTab === BLACKLISTED_TAB) {
      if (currentUser?.is_quickcare_admin) {
        return privateEducatorsList ?? []
      }
      return blacklistedUsers ?? []
    }

    if (currentTab === PENDING_TAB) {
      return pendingOrgUsers ?? []
    }

    return orgUsers ?? []
  }

  const navigate = useNavigate()

  const navigateToDetail = (user_id) => {
    setLocalStorageEducatorTab(currentTab)
    navigate(`/educators/${user_id}`)
  }

  return (
    <div>
      <Row>
        <M3TitleLarge>Educator management</M3TitleLarge>
        {(isOwner(org)) && (
          <AddButton
            onClick={handleAddUserModal}
            text="Send invite"
            theme={theme}
          ></AddButton>
        )}
      </Row>
      <Box sx={{ borderBottom: 1, borderColor: theme.palette.outline.main }}>
        <CustomTabs
          value={currentTab}
          onChange={handleChangeTab}
          indicatorColor="info"
          centered
        >
          <CustomTab
            label={currentUser?.is_quickcare_admin ?  "QuickCare educators" : "Organisation educators" }
            icon={
              <CustomBadge
                badgeContent={acceptedCount.toString()}
              />
            }
            style={{ justifyContent: 'space-evenly' }}
            iconPosition="end"
          />
          <CustomTab
            label="Pending invites"
            icon={
              <CustomBadge
                badgeContent={pendingCount.toString()}
              />
            }
            style={{ justifyContent: 'space-evenly' }}
            iconPosition="end"
          />
          <CustomTab
            label={currentUser?.is_quickcare_admin ?  "Private educators" : "Blocked educators" }
            icon={
              <CustomBadge
                badgeContent={currentUser?.is_quickcare_admin ?  privateEducatorsCount.toString() : blacklistedCount.toString() }
              />
            }
            style={{ justifyContent: 'space-evenly' }}
            iconPosition="end"
          />
        </CustomTabs>
      </Box>
      <PaddingBox />
      <SpacedRow>
        {currentTab === ACCEPTED_TAB ? (
          <Row>
            <FilterButtonMultiple
              currentValue={selectedQualifications}
              onChange={handleQualificationChange}
              options={qualificationList ?? []}
              label='qualification'
              placeholder="Qualification level"
            ></FilterButtonMultiple>
            <FilterButton
              currentValue={availableStatus}
              onChange={(e) => {
                setAvailableStatus(e.target.value)
              }}
              options={availableFilter}
            ></FilterButton>
            <FilterButton
              currentValue={selectedEducatorStatus}
              onChange={(e) => {
                setSelectedEducatorStatus(e.target.value);
              }}
              options={currentUser?.is_quickcare_admin ? educatorStatusFilter: [educatorStatusFilter[0], educatorStatusFilter[1]]}
            ></FilterButton>
          </Row>
        ) : currentTab === BLACKLISTED_TAB ? (
          <Row>
            <FilterButtonMultiple
              currentValue={selectedQualifications}
              onChange={handleQualificationChange}
              label='qualification'
              options={qualificationList ?? []}
              placeholder="Qualification level"
            ></FilterButtonMultiple>
            {currentUser?.is_quickcare_admin && (
              <>
              <FilterButton
                currentValue={availableStatus}
                onChange={(e) => {
                  setAvailableStatus(e.target.value)
                }}
                options={availableFilter}
              ></FilterButton>
              <FilterButton
                currentValue={selectedEducatorStatus}
                onChange={(e) => {
                  setSelectedEducatorStatus(e.target.value);
                }}
                options={educatorStatusFilter}
              ></FilterButton>
              </>
            )}
          </Row>
        ) : null}
        <PaddingBox />
        <SearchBox
          value={valueSearch}
          placeholder="Search for an educator by name, email, or phone number"
          onChange={onSearchTextChange}
        ></SearchBox>
      </SpacedRow>
      {isFechingList ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: '200px' }}
        >
          {' '}
          <CircularProgress />{' '}
        </Grid>
      ) : getListDataByTab().length > 0 && !isFechingList ? (
        searchString.length > 0 ? (
          <Box>
            {getListDataByTab().filter((user) => {
              if (user.email === null) return false
              return user.email
                .toLowerCase()
                .includes(searchString.toLowerCase())
            }).length > 0 ? (
              <Box>
                <Divider sx={{ marginTop: '16px' }}>
                  {' '}
                  <M3BodyMedium> Matched by email</M3BodyMedium>
                </Divider>
                {getListDataByTab().filter((user) => {
              if (user.email === null) return false
              return user.email
                .toLowerCase()
                .includes(searchString.toLowerCase())
            }).map((user) => {
                  return (
                    <EducatorSelection
                      key={user.id}
                      style={{ padding: '10px' }}
                      user={user}
                      isQuickcareAdmin={
                        currentUser && currentUser?.is_quickcare_admin
                      }
                      navigateToDetail={navigateToDetail}
                      handleEditUserModal={handleEditUserModal}
                      currentTab={currentTab}
                      setUserToCancel={setUserToCancel}
                      setOpenCancelInvitationModal={
                        setOpenCancelInvitationModal
                      }
                      handleRemoveBlacklistModal={handleRemoveBlacklistModal}
                      qualificationList={qualificationList}
                    />
                  )
                })}
              </Box>
            ) : null}
            {getListDataByTab().filter((user) => {
              if (user.first_name + ' ' + user.last_name === null) return false
              return (user.first_name + ' ' + user.last_name)
                .toLowerCase()
                .includes(searchString.toLowerCase())
            }).length > 0 ? (
              <Box>
                <Divider sx={{ marginTop: '16px' }}>
                  {' '}
                  <M3BodyMedium> Matched by name</M3BodyMedium>
                </Divider>
                {getListDataByTab()
                  .filter((user) => {
                    return (user.first_name + ' ' + user.last_name)
                      .toLowerCase()
                      .includes(searchString.toLowerCase())
                  })
                  .map((user) => {
                    return (
                      <EducatorSelection
                        key={user.id}
                        style={{ padding: '20px' }}
                        user={user}
                        isQuickcareAdmin={
                          currentUser && currentUser?.is_quickcare_admin
                        }
                        navigateToDetail={navigateToDetail}
                        handleEditUserModal={handleEditUserModal}
                        currentTab={currentTab}
                        setUserToCancel={setUserToCancel}
                        setOpenCancelInvitationModal={
                          setOpenCancelInvitationModal
                        }
                        handleRemoveBlacklistModal={handleRemoveBlacklistModal}
                        qualificationList={qualificationList}
                      />
                    )
                  })}
              </Box>
            ) : null}
            {getListDataByTab().filter((user) => {
              if (user.phone_number === null) return false
              return user.phone_number
                .toLowerCase()
                .includes(searchString.toLowerCase())
            }).length > 0 ? (
              <Box>
                <Divider sx={{ marginTop: '16px' }}>
                  {' '}
                  <M3BodyMedium> Matched by phone number</M3BodyMedium>
                </Divider>
                {getListDataByTab()
                  .filter((user) => {
                    if (user.phone_number === null) return false
                    return user.phone_number
                      .toLowerCase()
                      .includes(searchString.toLowerCase())
                  })
                  .map((user) => {
                    return (
                      <EducatorSelection
                        key={user.id}
                        style={{ padding: '10px' }}
                        user={user}
                        isQuickcareAdmin={
                          currentUser && currentUser?.is_quickcare_admin
                        }
                        navigateToDetail={navigateToDetail}
                        handleEditUserModal={handleEditUserModal}
                        currentTab={currentTab}
                        setUserToCancel={setUserToCancel}
                        setOpenCancelInvitationModal={
                          setOpenCancelInvitationModal
                        }
                        handleRemoveBlacklistModal={handleRemoveBlacklistModal}
                        qualificationList={qualificationList}
                      />
                    )
                  })}
              </Box>
            ) : null}
          </Box>
        ) : (
          <UserList
            currentTab={currentTab}
            getListDataByTab={getListDataByTab}
            currentUser={currentUser}
            orgUsers={currentTab === PENDING_TAB? pendingOrgUsers: orgUsers}
            navigateToDetail={navigateToDetail}
            handleEditUserModal={handleEditUserModal}
            qualificationList={qualificationList}
            privateEducatorsList={privateEducatorsList}
            handleRemoveBlacklistModal={handleRemoveBlacklistModal}
            setOpenCancelInvitationModal={setOpenCancelInvitationModal}
            setUserToCancel={setUserToCancel}
            blacklistedUsers={blacklistedUsers}
          />
        )
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: '200px' }}
          ƒ
        >
          {' '}
          <M3TitleLarge>No educators found</M3TitleLarge>{' '}
        </Grid>
      )}
      <CancelInvitationModal
        open={openCancelInvitationModal}
        onClose={handleCloseCancelModal}
        onClickCancel={() => cancelInvitation(userToCancel?.id)}
        name={userToCancel?.first_name + ' ' + userToCancel?.last_name}
        email={userToCancel?.email}
      ></CancelInvitationModal>
      {<OrgEducatorInviteForm
        open={openAddUserModal}
        setOpen={() => {
          setUserError(null)
          setOpenAddUserModal(false)
        }}
        isLoading={isAddingUser}
        handleSaveUser={handleAddUser}
        qualificationLevels={qualificationList}
        ectLevelList={ectLevelList}
        percentCompletes={percentCompletes}
        error={userError}
        setError={(e) => setUserError(e)}
        isAdmin={currentUser?.is_quickcare_admin}
      />
      }
      <SuccessDialogModel
        onClose={() => {
          setSuccessTitle(null)
          setShowSuccess(false)
        }}
        open={showSuccess}
        title={successTitle ?? 'New login created'}
        description={
          `<p>An email and SMS has been sent to the user informing them of this invitation.</p><p>${content}</p>`
        }
      />
      <OrgEducatorEditForm
        open={openEditUserModal}
        user={userToEdit}
        setOpen={setOpenEditUserModal}
        handleSaveUser={handleEditUser}
        error={userError}
        qualificationLevels={qualificationList}
      />
      {userToEdit && (
        <RemoveBlacklistForm
          open={openRemoveBlacklistModal}
          educatorInfo={userToEdit}
          setOpen={setOpenRemoveBlacklistModal}
          setAlert={setAlert}
          refreshShift={fetchDataBlacklisted}
          blacklistedInfo={userToEdit}
        />
      )}
    </div>
  )
}

const EducatorSelection = ({
  style,
  user,
  isQuickcareAdmin,
  navigateToDetail,
  currentTab,
  setUserToCancel,
  setOpenCancelInvitationModal,
  handleRemoveBlacklistModal,
  deactivateUser,
  reactivateUser,
  qualificationList
}) => {
  if (currentTab === ACCEPTED_TAB)
    return (
      <EducatorRow
        style={style}
        user={user}
        reactivateUser={reactivateUser}
        deactivateUser={deactivateUser}
        callback={() => {
          navigateToDetail(user.id)
        }}
        qualifications={qualificationList ?? []}
      />
    )
  else if (currentTab === PENDING_TAB)
    return (
      <PendingEducatorRow
        style={style}
        key={user.id}
        user={user}
        onCancelClick={() => {
          setUserToCancel(user)
          setOpenCancelInvitationModal(true)
        }}
      />
    )
  else if (currentTab === BLACKLISTED_TAB && isQuickcareAdmin)
    return (
      <EducatorRow
        style={style}
        key={user.id}
        user={user}
        qualifications={qualificationList ?? []}
        isPrivate={true}
        callback={() => {
          navigateToDetail(user.id)
        }}
      />
    )
  else
    return (
      <BlacklistedRow
        style={style}
        callback={() => {
          handleRemoveBlacklistModal(user)
        }}
        key={user.id}
        user={user}
        qualifications={qualificationList ?? []}
      />
    )
}
const UserList = ({
  currentTab,
  getListDataByTab,
  currentUser,
  orgUsers,
  navigateToDetail,
  qualificationList,
  privateEducatorsList,
  handleRemoveBlacklistModal,
  setOpenCancelInvitationModal,
  setUserToCancel,
  blacklistedUsers,
  deactivateUser,
  reactivateUser
}) => {
  
  return (
    <AutoSizer style={{ height: '50vh' }}>
      {({ height, width }) => (
        <List
          className="List"
          height={height}
          itemCount={getListDataByTab().length}
          itemSize={
            currentTab === BLACKLISTED_TAB && currentUser.is_quickcare_admin
              ? 100
              : 80
          }
          width={width}
        >
          {({ index, style }) => {
            if (currentTab === ACCEPTED_TAB)
              return (
                <EducatorRow
                  style={style}
                  key={orgUsers[index].id}
                  user={orgUsers[index]}
                  reactivateUser={(org_id) => {
                    reactivateUser(orgUsers[index], org_id)
                  }}
                  deactivateUser={(org_id) => {
                    deactivateUser(orgUsers[index], org_id)
                  }}
                  callback={() => {
                    navigateToDetail(orgUsers[index].id)
                  }}
                  qualifications={qualificationList ?? []}
                />
              )
            else if (currentTab === PENDING_TAB)
              return (
                <PendingEducatorRow
                  style={style}
                  key={orgUsers[index].id}
                  user={orgUsers[index]}
                  onCancelClick={() => {
                    setUserToCancel(orgUsers[index])
                    setOpenCancelInvitationModal(true)
                  }}
                />
              )
            else if (
              currentTab === BLACKLISTED_TAB &&
              currentUser?.is_quickcare_admin
            )
              return (
                <EducatorRow
                  style={style}
                  key={privateEducatorsList[index].id}
                  user={privateEducatorsList[index]}
                  qualifications={qualificationList ?? []}
                  reactivateUser={(org_id) => {
                    reactivateUser(privateEducatorsList[index], org_id)
                  }}
                  deactivateUser={(org_id) => {
                    deactivateUser(privateEducatorsList[index], org_id)
                  }}
                  isPrivate={true}
                  callback={() => {
                    navigateToDetail(privateEducatorsList[index].id)
                  }}
                />
              )
            else
              return (
                <BlacklistedRow
                  style={style}
                  callback={() => {
                    handleRemoveBlacklistModal(blacklistedUsers[index])
                  }}
                  key={blacklistedUsers[index].id}
                  user={blacklistedUsers[index]}
                  qualifications={qualificationList ?? []}
                />
              )
          }}
        </List>
      )}
    </AutoSizer>
  )
}
export default Educators