import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Box, Typography, IconButton, Grid, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilePreview from '../../../reusable/FilePreview';
import { get } from "../../../../utils/api";

const EducatorDocumentModal = ({ open, onClose, educatorId, orgId, isAdmin }) => {

  const baseUrl = "/file-content?filePath=";
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(true);

  const loadMember = useCallback(async () => {
    if(!educatorId) {
      return;
    }
    try {
      const response = await get(`/onboarding?educator_id=${educatorId}&org_id=${orgId}`);
      setMember(response);
    } catch (error) {
      console.error("Failed to fetch members:", error);
    } finally {
      setLoading(false);
    }
  }, [educatorId, orgId]);

  useEffect(() => {
    loadMember();
  }, [educatorId, orgId, loadMember]);

  const documents = [
    { name: "Qualification", url: member.qualification_url },
    { name: "Previous Qualification", url: member.previous_qualification_url },
    { name: "Resume", url: member.resume_url },
    { name: "CPR Certificate", url: member?.cpr_cert_url },
    { name: "First Aid Certificate", url: member?.first_aid_cert },
    { name: "Child Protectiom Certificate", url: member?.child_protection_cert },
    { name: "Transcript", url: member.transcript_url },
  ].filter(doc => doc.url);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle }}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          Educator Documents
        </Typography>
        <Box sx={{ display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
        <Grid container spacing={2} sx={{ mt: 2, overflow: 'auto', maxHeight: '80%', display: loading ? 'none' : 'flex' }}>
          {documents.length > 0 ? (
            documents.map((doc, index) => (
              <Grid item xs={12} sm={documents.length > 3 ? 6 : 12} key={index}>
                <FilePreview
                  key={index}
                  fileUrl={`${baseUrl}${doc.url}`}
                  title={doc.name}
                  verifyCallback={() => console.log('Verify callback')}
                  isShowDocumentButton={isAdmin}
                />
              </Grid>
            ))
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center', width: '100%' }}>
              No documents available.
            </Typography>
          )}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '90%',
  overflow: 'auto',
};

export default EducatorDocumentModal;