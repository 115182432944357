/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { get, getFileAsBlob, post } from "../../../../../utils/api";
import { useTheme } from "@mui/material/styles";
import { SuccessDialogModel } from "../../../../reusable/Modal";
import { BackButton } from "../../../../reusable/Buttons";
import {
  PaddingBox,
} from "../../../../reusable/Scaffolds";
import InfoTab from "./info_tab";
import CertTab from "./cert_tab";
const profileDetailStyles = {
  label: {
    width: 150,
    fontWeight: "bold",
    textAlign: "right",
    marginRight: "10px",
    fontSize: "13px",
  },
  value: { marginLeft: "10px", fontSize: "13px" },
  container: { display: "flex" },
};

const openToMap = {
  fullTime: "Full time",
  partTime: "Part time",
  casual: "Casual",
  childMinding: "Child Minding",
};
const ageGroupMap = {
  "0_24_months": " 0 - 24 Months",
  "2_3_years": "2 - 3 Years",
  "4_6_years": "3 - 6 Years",
  oosh: "OOSH",
};
const noticePeriodMap = {
  "0_2_weeks": "0 - 2 Weeks",
  "2_4_weeks": "2 - 4 Weeks",
  "4_plus_weeks": "4+ Weeks",
};

const MemberDetail = ({ setAlert }) => {

  const { member_id } = useParams();
  const [member, setMember] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
    useState(false);
  const [successTitle, setSuccessTitle] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [content, setContent] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();
  const baseUrl = "/file-content?filePath=";
  const theme = useTheme();
  const handleBackButton = () => {
    navigate("/applications");
  };
  const getOpenTo = (openTo) => {
    return openTo
      ?.map((item) => (openToMap[item] ? openToMap[item] : ""))
      .filter((item) => item !== "")
      .join(", ");
  };

  const getAgeGroup = (ageGroup) => {
    return ageGroup
      ?.map((item) => (ageGroupMap[item] ? ageGroupMap[item] : ""))
      .filter((item) => item !== "")
      .join(", ");
  };

  const getNoticePeriod = (noticePeriod) => {
    return noticePeriodMap[noticePeriod] ? noticePeriodMap[noticePeriod] : "";
  };

  const loadMemberIdentityLink = useCallback(async () => {
    try {
      const identityLink = await get(`/admin/onboarding/identity/${member_id}`);
      return identityLink;
    } catch (error) {
      return null;
    }
  }, [member_id]);

  const loadMember = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await get(`/admin/onboarding/${member_id}`);
      let avatar = null;
      if (response.photo_url) {
        avatar = await getFileAsBlob(`${baseUrl}${response.photo_url}`);
      }
      const identityLink = await loadMemberIdentityLink();
      setMember({ ...response, avatar, identityLink });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to fetch members:", error);
    }
  }, [loadMemberIdentityLink, member_id]);

  const getWWCCData = (wwcc_data) => {
    if (wwcc_data && Array.isArray(wwcc_data)) {
      return wwcc_data
        .map((entry) => `${entry.wwcc_number} - ${entry.state}`)
        .join(",  ");
    }
    return "";
  };
  const inviteQuickcare = async () => {
    setIsLoading(true);
    await post(`/manage/organisations/0/people`, {
      email: member.email,
      phone_number: member.phone_number,
      access: "educator",
    })
      .then((res) => {
        loadMember();
        if (res.content) {
          setContent(res.content);
        }
        setShowSuccess(true);
        setIsLoading(false);
      })
      .catch((error) => {
        loadMember();
        setAlert(error.message, "error");
        setIsLoading(false);
      });
  };

  const isCompleteStatus = () => {
    return (
      member.prefered_name &&
      member.identity_verified &&
      member.is_verify_wwcc &&
      member.is_verify_qualification &&
      member.video_url
    );
  };

  useEffect(() => {
    loadMember();
  }, []);
  
  return (
    <>
      <Box sx={{ mx: "auto", p: 3, marginTop: "-50px" }}>
        <BackButton
          title="Back to Members"
          onClick={handleBackButton}
        ></BackButton>
        <PaddingBox></PaddingBox>
        <InfoTab
          member={member}
          openModal={openModal}
          profileDetailStyles={profileDetailStyles}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          isLoading={isLoading}
          getOpenTo={getOpenTo}
          getAgeGroup={getAgeGroup}
          getNoticePeriod={getNoticePeriod}
          getWWCCData={getWWCCData}
          theme={theme}
          inviteQuickcare={inviteQuickcare}
          isCompleteStatus={isCompleteStatus}
        />
        <Divider />
        <PaddingBox />
        <Box sx={{ textAlign: "left" }}>
          <Typography variant="h6">Documents</Typography>
        </Box>
        <PaddingBox />
        {isLoading ? (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <CertTab
            member={member}
            refresh={loadMember}
            user_info_id={member.id}
            person_id={member.person_id}
          />
        )}
        <SuccessDialogModel
          onClose={() => {
            setSuccessTitle(null);
            setShowSuccess(false);
          }}
          open={showSuccess}
          title={successTitle ?? "New login created"}
          description={`<p>An email and SMS has been sent to the user informing them of this invitation.</p><p>${content}</p>`}
        />
      </Box>
    </>
  );
};
export default MemberDetail;
