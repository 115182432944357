import { useCallback, useState } from 'react';
import { get } from '../../../utils/api';

const useFetchAssignOption = () => {
    const [assignOptions, setAssignOptions] = useState([])

    const fetchAssignOptions = useCallback(
        async (orgId, centerId) => {
        try {
            const res = await get(
            `/manage/organisation/${orgId}/assignment_group_options`,
            null,
            { centre_id: centerId }
            )
            setAssignOptions(res)
        } catch (error) { }
        },
        []
    )
    return { fetchAssignOptions, assignOptions, setAssignOptions }
}

export { useFetchAssignOption };

