import {
  Box,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { get, post, put } from '../../../utils/api'
import {
  assignmentGroupType,
  CANCELLED,
  COMPLETED,
  EXPIRED,
  FILLED,
  FINISHED,
  IN_PROGRESS,
  OPEN,
  SHIFT_STATES
} from '../../../utils/constants'
import { OrgContext } from '../../../utils/context'
import {
  checkIfShiftIsOlderTwoDaysThanToday,
  convertTimeWithoutSeconds,
  convertToVerbalDate,
  debounce,
  stringAvatar,
} from '../../../utils/helper_functions'
import theme from '../../../utils/theme'
import { CustomAvatar } from '../../Layout'
import CustomBadge from '../../reusable/BadgeStyles'
import {
  BackButton,
  PrimaryButton,
  RemoveButton
} from '../../reusable/Buttons'
import CancelShiftModal, { AdjustBillableHoursModal } from '../../reusable/Modal'
import {
  CustomTab,
  CustomTabs,
  PaddingBox,
  SpacedRow
} from '../../reusable/Scaffolds'
import { Tags } from '../../reusable/Tags'
import ShiftMessageTab from './MessagesTab'
import ShiftDetailTab from './ShiftDetailTab'

export const decideTagType = (shift) => {
  if (!shift) return OPEN
  switch (shift.state) {
    case 'cancelled':
    case 'cancelled_late':
      return CANCELLED;
    case 'completed':
      return COMPLETED;
    case 'finished':
      return FINISHED;
    case 'available':
      return OPEN;
    case 'filled':
      return FILLED;
    case 'in_progress':
      return IN_PROGRESS;
    case 'expired':
      return EXPIRED
    default:
      return OPEN
  }
}


const ShiftDetail = ({ currentUser, setAlert }) => {
  const navigate = useNavigate()
  const { shift_id } = useParams()
  const [shift, setShift] = React.useState(null)
  const [educatorInfo, setEducatorInfo] = React.useState(null)
  const [currentTab, setCurrentTab] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [openModalAdjustHours, setOpenModalAdjustHours] = React.useState(false)
  const [unreadNumber, setUnreadNumber] = React.useState(0)
  const tabValues = [
    { name: 'Messages', value: 0 },
    { name: 'Shift details', value: 1 }
  ]
  const location = useLocation()

  const handleTabChange = (event, newValue) => {
    debounce(setCurrentTab(newValue), 500)
    fetchUnreadNumberMessageTab()
  }
  const { organisation_id: orgOrganisationId } = useContext(OrgContext) || {};
  const org_id = currentUser?.is_quickcare_admin
    ? shift?.organisation_id ?? null
    : orgOrganisationId ?? null;

  const cancelShift = async () => {
    try {
      await post(`/manage/shifts/${shift_id}/deactive`, {
        reason: 'Cancelled by employer'
      })
      setAlert('Shift cancelled successfully')
    } catch (error) {
      setAlert('Shift cancel failed', 'error')
    }
    navigate('/shifts')
  }

  const fetchShiftData = useCallback(async () => {
    try {
      const shift = await get(`/manage/shifts/${shift_id}`)
      setShift(shift)
      if (shift.educator_id) {
        const educatorInfo = await get(`/manage/people/shifts/${shift_id}`)
        setEducatorInfo(educatorInfo.user)
      }
    } catch (error) {
      navigate('/shifts/error')
    }
  }, [shift_id, navigate])

  const fetchShift = useCallback(async () => {
    setIsLoading(true)

    await fetchShiftData()

    setIsLoading(false);
  }, [fetchShiftData]);


  const constShiftNoticeTitle = (shift) => {
    if (decideTagType(shift) === OPEN && (shift.assigment_types === assignmentGroupType.invidualAssigned || shift.assigment_types === assignmentGroupType.favorite)) {
      return 'pending'
    }
    if (decideTagType(shift) === OPEN && shift.assigment_types === assignmentGroupType.invidualRejected) {
      return 'declined'
    }
    return ''
  }

  const isDisableCancelShiftButton = (shiftState) => {
    return shiftState === SHIFT_STATES.AVAILABLE || shiftState === SHIFT_STATES.FILLED
  }

  const fetchUnreadNumberMessageTab = useCallback(async () => {
    let shiftConversations = []
    try {
      shiftConversations = await get(`/manage/conversations/${shift_id}`)
    } catch (error) {
      shiftConversations = {
        conversations: []
      }
    }

    // Count unread number message
    let unreadNumberConversation = 0;
    for (let index in shiftConversations.conversations) {
      unreadNumberConversation = unreadNumberConversation + parseInt(shiftConversations.conversations[index].unread_number ?? 0)
    }
    setUnreadNumber(unreadNumberConversation)
  }, [shift_id])

  const handleAdjustHours = async (body) => {
    if (body.check_in > body.check_out)
      return setAlert('Check in time must be before check out time', 'error')
    try {
      await put(`/manage/shifts/${shift_id}/adjust_hours`, body)
      setAlert('Shift has adjusted billable hours.')
      fetchShift()
    } catch (error) {
      setAlert(error.message, 'error')
    }
    setOpenModalAdjustHours(false)
  }

  const handleBackButton = () => {
    const from = new URLSearchParams(location.search).get('from')
    from === 'educator_detail'
      ? navigate(`/educators/${shift.educator_id}`)
      : navigate('/shifts')
  }
  const getShiftEducatorName = (educatorInfo) => {
    const shouldShowUserName =  (shift.educator.id && !shift.assigment_types.startsWith('individual')) ||
    (shift.state !== SHIFT_STATES.AVAILABLE && shift.educator.id)
    return shouldShowUserName
      ? shift.educator.first_name +
          (educatorInfo?.prefered_name
            ? ' (' + educatorInfo?.prefered_name + ') '
            : ' ' + shift.educator.last_name)
      : 'Unfilled'
  }
  const showAdjustedBillableHoursButton = (shift) => {
   return (
     decideTagType(shift) === COMPLETED ||
     (decideTagType(shift) === FINISHED &&
       shift.educator_id &&
       checkIfShiftIsOlderTwoDaysThanToday(shift.shift_date))
   ) 
  }
  const getButtonBackTitle = () => {
    const from = new URLSearchParams(location.search).get('from')
    let backButtonTitle
    switch (from) {
      case 'educator_detail':
        backButtonTitle = 'Back to Educator'
        break
      default:
        backButtonTitle = 'Back to Shifts'
        break
    }
    return backButtonTitle
  }

  useEffect(() => {
    fetchShift()
    fetchUnreadNumberMessageTab()
  }, [fetchShift, fetchUnreadNumberMessageTab])
  return (
    <div>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          {' '}
          <CircularProgress />{' '}
        </Box>
      ) : (
        <Box>
          {shift != null ? (
            <AdjustBillableHoursModal
              open={openModalAdjustHours}
              shift={shift}
              educatorFilled={educatorInfo}
              onClose={() => {
                setOpenModalAdjustHours(false)
              }}
              onSave={handleAdjustHours}
            />
          ) : null}
          <BackButton
            title={getButtonBackTitle()}
            onClick={handleBackButton}
          ></BackButton>
          <PaddingBox />
          <Tags type={decideTagType(shift)}></Tags>
          <PaddingBox />
          {shift !== null ? (
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <CustomAvatar
                  {...stringAvatar(shift.centre.name)}
                  src={
                    shift.centre.images !== null &&
                    shift.centre.images.length > 0
                      ? shift.centre.images[0]
                      : null
                  }
                />
              </ListItemAvatar>
              <SpacedRow>
                <ListItemText
                  primary={<span>{shift.centre.name}</span>}
                  secondary={
                    <React.Fragment>
                      <span>
                        {getShiftEducatorName(educatorInfo) + ' '}
                        {constShiftNoticeTitle(shift) !== '' && (
                          <span
                            style={{
                              color:
                                constShiftNoticeTitle(shift) === 'pending'
                                  ? theme.palette.blueJeans.main
                                  : 'red'
                            }}
                          >
                            ({constShiftNoticeTitle(shift)})
                          </span>
                        )}
                      </span>
                      <br />
                      <span>
                        {convertToVerbalDate(shift.shift_date) +
                          ' | ' +
                          convertTimeWithoutSeconds(shift.shift_start) +
                          ' - ' +
                          convertTimeWithoutSeconds(shift.shift_end)}
                      </span>
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  {decideTagType(shift) === FILLED ||
                  decideTagType(shift) === OPEN ? (
                    <RemoveButton
                      isDisabled={isDisableCancelShiftButton(
                        shift.shift_state
                      )}
                      text="Cancel shift"
                      onClick={() => {
                        setOpenModal(true)
                      }}
                    ></RemoveButton>
                  ) : showAdjustedBillableHoursButton(shift) ? (
                    <PrimaryButton
                      text="Adjust billable hours"
                      onClick={() => {
                        setOpenModalAdjustHours(true)
                      }}
                    ></PrimaryButton>
                  ) : null}
                </ListItemSecondaryAction>
                <CancelShiftModal
                  open={openModal}
                  onClose={() => {
                    setOpenModal(false)
                  }}
                  onClickCancel={() => {
                    cancelShift()
                  }}
                />
              </SpacedRow>
            </ListItem>
          ) : null}

          <Box
            sx={{ borderBottom: 1, borderColor: theme.palette.outline.main }}
          >
            <CustomTabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="info"
            >
              <CustomTab
                label={tabValues[0].name}
                icon={<CustomBadge badgeContent={unreadNumber} color="error" />}
                iconPosition="end"
              />
              <CustomTab label={tabValues[1].name} />
            </CustomTabs>
          </Box>
          {currentTab === 0 && (
            <ShiftMessageTab
              shift_id={shift_id}
              currentUser={currentUser}
              shiftInfo={shift}
              refreshUnreadNumberMessageTab={fetchUnreadNumberMessageTab}
            ></ShiftMessageTab>
          )}
          {currentTab === 1 && (
            <ShiftDetailTab
              shiftData={shift}
              educatorInfo={educatorInfo}
              statusShift={decideTagType(shift)}
              setAlert={setAlert}
              reload={fetchShift}
              backButtonHandler={handleBackButton}
              currentUser={currentUser}
              orgId={org_id}
            ></ShiftDetailTab>
          )}
        </Box>
      )}
    </div>
  )
}

export default ShiftDetail

