
import React, { useEffect, useCallback } from 'react'
import {
  Box,
  ListItem,
  ListItemText,
  CircularProgress,
  List,
  Grid,
  LinearProgress,
  Divider} from '@mui/material'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import {
  LabelDivider,
  PaddingBox,
  Row} from '../../../reusable/Scaffolds'
import quickcareLogo from '../../../../assets/splash.png'
import {
  checkIfDateIn5Day,
  convertDateWithDayAndYear,
  convertTimeOnly} from '../../../../utils/helper_functions'
import { get, post } from '../../../../utils/api'
import { stringAvatar } from '../../../../utils/helper_functions'
import theme from '../../../../utils/theme'
import {
  M3BodyLarge,
  M3BodySmall,
  M3BodyMedium,
  M3LabelSmall,
  M3TitleSmall,
  M3LabelMedium
} from '../../../reusable/TextStyles'
import {
  SearchInput} from '../../../reusable/TextField'
import { CustomAvatar } from '../../../Layout'

const ShiftMessageTab = ({ shift_id, currentUser, shiftInfo, refreshUnreadNumberMessageTab }) => {
    const [shiftConversations, setshiftConversations] = React.useState([])
    const [currentConversationId, setCurrentConversationId] = React.useState(null)
    const [currentMessages, setCurrentMessages] = React.useState([])
    const [currentContent, setCurrentContent] = React.useState('')
    const [currentUserName, setCurrentUserName] = React.useState('')
    const [sendingMessage, setSendingMessage] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const sendMessage = async () => {
      if (!currentConversationId || currentContent === '') return
      setSendingMessage(true)
      await post(`/messages/${currentConversationId}`, {
        content: currentContent
      })
      setCurrentContent('')
      setSendingMessage(false)
      fetchShiftConversationMessages()
    }
  
    const fetchShiftConversationMessages = useCallback(async () => {
      if (!currentConversationId) return
      setIsLoading(true)
      const shiftMessage = await get(
        `/manage/messages/${currentConversationId}/0`
      )
  
      setIsLoading(false)
      setCurrentMessages(shiftMessage.messages.reverse())
    }, [currentConversationId])

    const resetNumberForShiftConversation = useCallback((conversationId) => {
      // Find the index of the conversation in the array
      const resetConversations = [...shiftConversations];
      const index = shiftConversations.findIndex(conversation => conversation.id === conversationId);
    
      // If the conversation was found, reset its number
      if (index !== -1) {
        resetConversations[index].unread_number_employer = 0;
      }
      setshiftConversations([...resetConversations]);
    }, [shiftConversations]);

    const resetUnreadNumberConversation = useCallback(async () => {
      if(currentUser?.is_quickcare_admin) return
      try {
        await post(
          `/manage/messages/${currentConversationId}/reset_unread_number`,
          {
            conversation_id: currentConversationId,
          }
        );
        refreshUnreadNumberMessageTab()
      } catch (error) {
        console.error(error)
      }
    }, [currentConversationId, currentUser?.is_quickcare_admin, refreshUnreadNumberMessageTab])
    const fetchShiftConversations = useCallback(async (initital) => {
      setIsLoading(true)
      if (!shift_id) return
      let shiftConversations = []
      try {
        shiftConversations = await get(`/manage/conversations/${shift_id}`)
      } catch (error) {
        shiftConversations = {
          conversations: []
        }
      }
      // put conversation with conversation.educator_id equal to conversation.shift.educator_id to top of the list
      const sortedConversations = shiftConversations.conversations.sort(
        (a, b) => {
          if (a.educator_id === a.shift.educator_id) return -1
          if (b.educator_id === b.shift.educator_id) return 1
          return 0
        }
      )
   
      setIsLoading(false)
      if(initital){
        if (sortedConversations.length> 0) {
          sortedConversations[0].unread_number_employer = 0
          resetUnreadNumberConversation(sortedConversations[0].id)
          sortedConversations[0].unread_number_employer = 0;

        }
      }
      setshiftConversations(sortedConversations)
      if (shiftConversations.conversations.length > 0) {
        if (!currentConversationId && shiftConversations.conversations) {
          setCurrentConversationId(shiftConversations.conversations[0].id)
          setCurrentUserName(
            shiftConversations.conversations[0].first_name +
              (shiftConversations.conversations[0].prefered_name
                ? " (" + shiftConversations.conversations[0].prefered_name + ") "
                : " ") +
              shiftConversations.conversations[0].last_name
          )
        }
        fetchShiftConversationMessages()
      }
    }, [shift_id, fetchShiftConversationMessages, currentConversationId, resetUnreadNumberConversation])

    function getUserName(message) {
      if (message.type === 'system') return 'QuickCare system'
      if (message.sender.id === currentUser.id) return 'You'
      if (message.type === 'educator')
        return message.sender.first_name + (message.sender.prefered_name ? ' (' + message.sender.prefered_name + ') ' : ' ') + message.sender.last_name
      return (
        message.sender.first_name +
        message.sender.last_name +
        ' @ ' +
        message.centre.name
      )
    }
    const changeConversation = (conversation) => {
      setCurrentMessages([])
      setCurrentUserName(
        conversation.first_name +
          (conversation.prefered_name
            ? ' (' + conversation.prefered_name + ') '
            : ' ') +
          conversation.last_name
      )
      resetNumberForShiftConversation(conversation.id)
      setCurrentConversationId(conversation.id)
      resetUnreadNumberConversation()
    }
    const getAvatarUrl = (message) => {
      if (message.type === 'system') return quickcareLogo
      if (message.type === 'educator') return message.sender.avatar_url
      return message.logo_url
    }
    const isDifferentDate = (messages, index) => {
      if (messages.length === index + 1) return true
      const currentDate = new Date(messages[index].created_at)
      const previousDate = new Date(messages[index + 1].created_at)
      return currentDate.getDate() !== previousDate.getDate()
    }
    useEffect(() => {
      const messageInterval = setInterval(() => {
        fetchShiftConversations(false)
      }, 5000)
      fetchShiftConversations(true)
      return () => {
        clearInterval(messageInterval)
      }
    }, [fetchShiftConversations])
  
    return (
      <Grid container>
        <Grid item xs={4} sx={{ height: '70vh' }}>
          {shiftConversations.length === 0 && isLoading ? (
            <Box
              sx={{
                height: '100%',
                width: '60vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          ) : shiftConversations.length === 0 && !isLoading ? (
            <Box
              sx={{
                height: '100%',
                width: '60vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <M3BodyLarge>No conversation found</M3BodyLarge>
            </Box>
          ) : (
            <List sx={{ width: '100%' }}>
              {shiftConversations.map((conversation, index) => (
                <div key={index}>
                  {conversation.educator_id ===
                  conversation.shift.educator_id ? (
                    <LabelDivider label={'Assigned'} />
                  ) : null}
                  {index > 0 &&
                  shiftConversations[index - 1].educator_id ===
                    conversation.shift.educator_id ? (
                    <div>
                      <PaddingBox />
                      <LabelDivider label={'Other'} />
                    </div>
                  ) : null}
                  <ListItem
                    onClick={() => changeConversation(conversation)}
                    key={conversation.id}
                    style={{
                      width: '100%',
                      backgroundColor:
                        currentConversationId === conversation.id
                          ? theme.palette.surfaceContainerHigh.main
                          : 'white'
                    }}
                  >
                    <ListItemText
                      primary={
                        <React.Fragment>
                          {conversation.unread_number_employer > 0 &&
                          conversation.last_message?.sender?.id !==
                            currentUser.id ? (
                            <M3TitleSmall>NEW</M3TitleSmall>
                          ) : null}
                          <M3BodyLarge>
                            {conversation.first_name +
                              (conversation.prefered_name
                                ? ' (' + conversation.prefered_name + ') '
                                : ' ') +
                              conversation.last_name}
                          </M3BodyLarge>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <M3BodyMedium>
                            {conversation.last_message
                              ? conversation.last_message.content
                              : ''}
                          </M3BodyMedium>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>
              ))}
            </List>
          )}
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            height: '70vh'
          }}
        >
          {shiftConversations.length === 0 ? null : (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px'
              }}
              sx={{
                backgroundColor: theme.palette.surfaceContainerHigh.main,
                height: '100%'
              }}
            >
              <M3BodyLarge
                style={{
                  textAlign: 'center',
                  paddingTop: '16px',
                  paddingBottom: '16px'
                }}
              >
                {' '}
                Messaging with {currentUserName}
              </M3BodyLarge>
              <Box style={{ marginTop: 'auto' }} />
              <Box
                style={{
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column-reverse'
                }}
              >
                {currentMessages.length === 0 && isLoading ? (
                  <LinearProgress />
                ) : (
                  currentMessages.map((message, index) => (
                    <div key={message.id}>
                      {isDifferentDate(currentMessages, index) ? (
                        <Divider
                          style={{
                            margin: '16px'
                          }}
                        >
                          <M3LabelMedium>
                            {convertDateWithDayAndYear(message.created_at)}
                          </M3LabelMedium>
                        </Divider>
                      ) : null}
                      <MessageChatBubbleComponent
                        avatarUrl={getAvatarUrl(message)}
                        message={message}
                        currentUser={currentUser}
                        index={index}
                        getUserName={getUserName(message)}
                      />
                    </div>
                  ))
                )}
              </Box>
              <PaddingBox></PaddingBox>
              {!checkIfDateIn5Day(shiftInfo.shift_date) ? (
                <M3BodyMedium style={{ textAlign: 'center' }}>
                  This shift has completed
                </M3BodyMedium>
              ) : (
                <SearchInput
                  sx={{
                    width: '100% !important', 
                    maxWidth: '100% !important'
                  }}
                  id="outlined-adornment-password"
                  type="text"
                  placeholder="Type a message here"
                  onChange={(e) => setCurrentContent(e.target.value)}
                  value={currentContent}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (sendingMessage) return
                      sendMessage()
                    }
                  }}
                  margin="dense"
                  style={{}}
                  endAdornment={
                    <SendOutlinedIcon
                      onClick={() => {
                        if (sendingMessage) return
                        sendMessage()
                      }}
                      sx={{ fill: theme.palette.primary.main }}
                    />
                  }
                ></SearchInput>
              )}
              <PaddingBox />
            </Box>
          )}
        </Grid>
      </Grid>
    )
  }
  
  const MessageChatBubbleComponent = ({message, currentUser, getUserName, index, avatarUrl}) => {
    return (
      <Box
        style={{
          marginRight: currentUser.id === message.sender.id ? '52px' : 'auto',
          marginLeft: currentUser.id === message.sender.id ? 'auto' : '52px',
          marginBottom: '16px',
          maxWidth: '40%',
          width: 'fit-content'
        }}
      >
        <Row style={{ alignItems: 'start' }}>
          {!(currentUser.id === message.sender.id) ? (
            <Row>
              <CustomAvatar
                style={{ marginTop: '6px' }}
                src={avatarUrl}
                sizes="32"
                {...stringAvatar(
                  message.sender.first_name + message.sender.last_name
                )}
              />
              <PaddingBox></PaddingBox>
            </Row>
          ) : null}
          <Box>
            <M3BodySmall
              style={{
                textAlign:
                  currentUser.id === message.sender.id ? 'end' : 'start'
              }}
            >
              {getUserName}
            </M3BodySmall>
            <Box
              // key={message.id}
              style={{
                padding: '12px',
                backgroundColor:
                  currentUser.id === message.sender.id
                    ? theme.palette.secondary.main
                    : 'white',
                // marginRight: currentUser.id === message.sender.id ? '0px' : 'auto',
                // marginLeft: currentUser.id === message.sender.id ? 'auto' : '0px',
                color: theme.palette.text.primary,
                borderRadius: '12px',
                marginTop: '4px',
                minHeight: '32px',
                boxShadow:
                  '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)'
              }}
            >
              <M3BodyMedium>{message.content}</M3BodyMedium>
            </Box>
            <M3LabelSmall
              style={{
                marginTop: '4px',
                textAlign:
                  currentUser.id === message.sender.id ? 'end' : 'start',
                color: theme.palette.text.main
              }}
            >
              {convertTimeOnly(message.created_at)}
            </M3LabelSmall>
          </Box>
          {currentUser.id === message.sender.id ? (
            <Row>
              <PaddingBox></PaddingBox>
              <CustomAvatar
                style={{ marginTop: '6px' }}
                src={
                  currentUser.is_employer
                    ? message.centre.centres_images
                      ? message.centre.centres_images[0]
                      : ''
                    : message.sender.avatar_url
                }
                sizes="32"
              />
            </Row>
          ) : null}
        </Row>
      </Box>
    )
  }

  export default ShiftMessageTab